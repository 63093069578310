<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float: left">
        <el-button type="primary" icon="el-icon-sold-out" @click="handle_export()">导出</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()"
          >刷新</el-button
        >
      </div>
      <div style="float: right">
        <el-input
          placeholder="雇主姓名手机或企业名称"
          v-model="keyword"
          class="input-with-select"
          style="width: 250px"
        ></el-input
        >&nbsp;

        <el-button
          type="primary"
          @click="handler_search()"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="page.list"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        border
        width="width:100%; height:50%"
      >
        <el-table-column type="index" label="顺序" width="50"></el-table-column>
        <el-table-column
          prop="employerUser.username"
          label="用户名"
          width="100"
        ></el-table-column>
       
        <el-table-column
          prop="employerUser.mobile"
          label="用户联系方式"
          width="100">
          </el-table-column>
        <el-table-column
          prop="employerUser.name"
          label="用户姓名"
          width="150"
        ></el-table-column>
        
        <el-table-column prop="companyname" label="企业名称" with="200">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="企业联系方式"
          width="200"
        ></el-table-column>
        <el-table-column prop="uid" label="企业地址">
          <template #default="scope">
            {{ scope.row?.province }} {{ scope.row?.city }}
            {{ scope.row?.area }} {{ scope.row?.street }}
          </template>
        </el-table-column>
        <el-table-column
          prop="employerUser.createtime"
          label="加入时间"
          width="100"
        >
          <template #default="scope">
            {{computeTime(scope.row?.createtime)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="employerUser.status"
          label="状态"
          width="60"
        >
          <template #default="scope">
            {{formatStatus(scope.row?.status)}}
          </template>
        </el-table-column>

        <el-table-column label="操作" conter width="180">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_edit(scope.row)"
              >编辑
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="addJob(scope.row)"
              >新建职位
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickresetpassword(scope.row)"
              >重置密码
            </el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              @click="handle_delete(scope.row)"
              >删除
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit" v-if="scope.row.status==0"
              @click="handle_verify(scope.row)"
              >审核
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit" v-if="scope.row.status==1"
              @click="handle_disverify(scope.row)"
              >取消审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <!--employer dialog-->
    <el-dialog
      title="雇主信息"
      :close-on-click-modal="false"
      width="600px"
      v-model="dlgShow"
    >
      <el-form ref="form" :model="cuttData" :rules="rules">
        <el-form-item label="用户姓名" label-width="100px" prop="name">
          <el-input v-model="cuttData.name"></el-input>
        </el-form-item>
        <el-form-item label="职务" label-width="100px" prop="title">
          <el-input v-model="cuttData.title"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" label-width="100px" prop="companyname">
          <el-input v-model="cuttData.companyname" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="企业成立时间" label-width="100px" prop="buildtime">
          <el-date-picker
            v-model="cuttData.buildtime"
            type="date"
            placeholder="选择成立时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="企业规模" label-width="100px" prop="scale">
          <el-select v-model="cuttData.scale" style="margin-right: 5px">
            <el-option
              v-for="item in scaleList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省" label-width="100px" prop="province">
          <el-select
            v-model="cuttData.province"
            style="margin-right: 5px"
            @change="handle_changeprovince"
          >
            <el-option
              v-for="(item, index) in provinces"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" label-width="100px" prop="city">
          <el-select
            v-model="cuttData.city"
            style="margin-right: 5px"
            @change="handle_changecity"
          >
            <el-option
              v-for="(item, index) in selectCityList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县(区)" label-width="100px" prop="area">
          <el-select v-model="cuttData.area" style="margin-right: 5px">
            <el-option
              v-for="(item, index) in selectAreaList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" label-width="100px" prop="street">
          <el-input v-model="cuttData.street"></el-input>
        </el-form-item>
        <el-form-item label="简介" label-width="100px" prop="description">
          <el-input type="textarea" v-model="cuttData.description"></el-input>
        </el-form-item>
        <el-form-item label="人力负责人" label-width="100px" prop="hr">
          <el-input v-model="cuttData.hr"></el-input>
        </el-form-item>
        <el-form-item label="企业联系电话" label-width="100px" prop="phone">
          <el-input v-model="cuttData.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" label-width="100px" prop="email">
          <el-input v-model="cuttData.email"></el-input>
        </el-form-item>

        <el-form-item label="公司执照图片" label-width="100px">
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            accept="image/jpeg,image/gif,image/png"
            :headers="uploadHeaders"
            :on-success="successUploadImage"
            :limit="1"
          >
          </el-upload>
          <el-image
            :src="computeImageUrl(cuttData?.uploadFile?.url)"
            v-show="!!cuttData.uploadFile"
          ></el-image>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShow = false">取 消</el-button>
        <el-button type="primary" @click="handle_validate('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--reset password dialog-->
    <el-dialog
      title="用户重置密码"
      :close-on-click-modal="false"
      width="600px"
      v-model="resetPasswordDlgShow"
    >
      <el-form ref="form1" :model="resetPasswordData" :rules="rules1">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="resetPasswordData.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input v-model="resetPasswordData.password"></el-input>
        </el-form-item>
        <el-form-item
          label="重复密码"
          label-width="100px"
          prop="verifyPassword"
        >
          <el-input v-model="resetPasswordData.verifyPassword"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="digShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_resetpasswordsave('form1')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EMPLOYER from "../../api/lianying/api_employer";
import USER from "../../api/lianying/api_user";
import scaleList from "../../common/scale.js";
import provinces from "../../common/province.js";
import cities from "../../common/city.js";
import areas from "../../common/area.js";
import * as API from "../../utils/request";
import moment from 'moment';

export default {
  name: "employerlist", // 雇主列表

  data() {
    return {
      actionUrl: "",

      keyword: "",
      showLoading: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      scaleList: scaleList,

      provinces: provinces,
      cities: cities,
      areas: areas,
      selectCityList: [],
      selectAreaList: [],

      dlgShow: false,
      uploadHeaders: {},

      resetPasswordDlgShow: false,
      resetPasswordData: {
        id: undefined,
        username: "",
        password: "",
        verifyPassword: "",
      },

      cuttData: {},

      FileList: [],
    };
  },
  // 初始化加载
  mounted() {
    this.init();
    let token = localStorage.getItem("access-token");
    this.uploadHeaders.Authorization = token;
    console.log("upload header " + JSON.stringify(this.uploadHeaders));
    this.actionUrl = API.getUrl() + "/uploadfile/uploadimg";
  },
  computed: {
    computeImageUrl: function () {
      return function (imageUrl) {
        console.log("image url " + JSON.stringify(imageUrl));
        if (!imageUrl) {
          return "";
        }
        if (imageUrl.indexOf("http") == 0) {
          return imageUrl;
        } else {
          let url = API.getImageUrl() + "" + imageUrl;
          console.log("url " + url);
          return url;
        }
      };
    },
    computeTime:function(){
      return function(time){
        return moment(time).format('YYYY-MM-DD hh:mm:ss');

      }
    },
    formatStatus:function(){
      return function(status){
        return status==1?"已审核":"未审核";
      }
    }
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
    
      console.log("keyword " + this.keyword);
      EMPLOYER.list({
        currentPage: this.page?.pageNum,
        pageSize: this.page?.pageSize,
        keyword: this.keyword,
      }).then((res) => {
        this.showLoading = false;
        console.log("list res " + JSON.stringify(res));
        if (1 == res.status) {
          var data = res?.data;
          this.page.total = data?.totalNumber;
          this.page.list = data?.data;

          console.log("page item 1 " + JSON.stringify(this.page.list[0]));
        } else {
          this.$message.error(res?.message);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    handle_edit(row) {
      console.log("edit employer " + JSON.stringify(row));
      if (row) {
        this.cuttData = row;
        if(!this.cuttData.uploadFile){
          this.cuttData.uploadFile={};
        }
        this.cuttData.userid = row.id;
      }
      this.dlgShow = true;
    },

    handler_search() {
      this.initPageData();
    },
    handle_changeprovince(e) {
      console.log("change province " + JSON.stringify(e));
      this.selectCityList = [];
      if (e) {
        this.selectAreaList = [];
        this.selectCityList = [];

        let idx = this.provinces.findIndex((item, index) => {
          return item.label == e;
        });

        console.log("province in index " + idx);
        if (idx > -1) {
          this.selectCityList = this.cities[idx];
          this.cuttData.province = this.provinces[idx].label;
        }
        console.log("city list " + JSON.stringify(this.selectCityList));
      }
    },
    handle_changecity(e) {
      console.log("change city " + JSON.stringify(e));
      this.selectAreaList = [];
      console.log("select city " + JSON.stringify(this.selectCityList));
      if (e) {
        let idx1 = this.provinces.findIndex((item, index) => {
          return item.label == this.cuttData.province;
        });
        let idx2 = this.selectCityList.findIndex((item, index) => {
          return item.label == e;
        });
        console.log("city index " + idx1 + ", " + idx2);
        if (idx1 > -1 && idx2 > -1) {
          this.selectAreaList = this.areas[idx1][idx2];
          this.cuttData.city = this.selectCityList[idx2].label;
        }
      }
      console.log("select area " + JSON.stringify(this.selectAreaList));
    },

    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save();
        } else {
          return false;
        }
      });
    },
    async handle_save() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      console.log("save employer " + JSON.stringify(this.cuttData));
      EMPLOYER.save(this.cuttData).then(this.res_handle);
    },
    //结果请求设置
    res_handle(res) {
      console.log("res " + JSON.stringify(res));
      this.dlgShow = this.lock = false;
      if (1 === res.status) {
        this.$message.success("操作成功！");
        //this.init();
      } else {
        this.$message.error(res.message);
      }
    },
    successUploadImage: function (res) {
      console.log("upload img " + JSON.stringify(res));
      if (res.status == 1) {
        if (!this.cuttData.uploadFile) {
          this.cuttData.uploadFile = {};
        }
        this.cuttData.uploadfileid = res.data.id;
        this.cuttData.uploadFile.url = res.data.url;
      }
    },
    //设置导出
    handle_export(row) {

      console.log("currentPage "+this.page.pageNum);

      if (!this.page.list || this.page.list.length == 0) {
        this.$message.error("没有数据导出");
        return;
      }
      let params = {
        currentPage: 1,
        pageSize: 1000000000,
        keyword: this.keyword,
      };
      
      EMPLOYER.exportBlob(params).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = window.URL.createObjectURL(new Blob([res]));
        link.setAttribute("download", "用户列表.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$message.success("操作成功！");
      });
    },
    addJob(row){
      console.log("addjob");
      
      this.$router.push({
        path: "/job/edit",
        query: {
          employerId: row?.id,
        },
      });

    },
    handle_delete(row){
      let name=row.companyname+'-'+row.employerUser.name;
     
      this.$confirm("此操作将删除[" + name + "] 雇主, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          //params.jobid = row.id;
          params.id=row.id;
          EMPLOYER.delete(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("成功删除");
              let idx=this.page.list.findIndex((item)=>{
                return item.id==row.id;
              })
              if(idx>=0){
                this.page.list.splice(idx,1);
              }
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    handle_clickresetpassword(row) {
      this.resetPasswordDlgShow = true;
      this.resetPasswordData.id = row.id;
      this.resetPasswordData.username = row.employerUser.username;
    },
    handle_resetpasswordsave(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let params = {
            password: this.resetPasswordData.password,
            verifyPassword: this.resetPasswordData.verifyPassword,
            id: this.resetPasswordData.id,
          };
          USER.resetPassword(params).then((res) => {
            this.resetPasswordDlgShow = false;
            if (res.status == 1) {
              this.$message.success("重置密码成功");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handle_verify(row){

      if(row.status){
        this.$message.error('雇主已经通过成功通过审核！');
        return;
      }
      let name=row.companyname+'-'+row.employerUser.name;
     
      this.$confirm("此操作将审核通过[" + name + "] 雇主, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          //params.jobid = row.id;
          params.id=row.id;
          EMPLOYER.verify(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("成功通过审核");
              let idx=this.page.list.findIndex((item)=>{
                return item.id==row.id;
              })
              this.page.list[idx].status=1;
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    handle_disverify(row){

      if(row.status==0){
        this.$message.error('雇主已经取消审核！');
        return;
      }
      let name=row.companyname+'-'+row.employerUser.name;
     
      this.$confirm("此操作将取消审核[" + name + "] 雇主, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          //params.jobid = row.id;
          params.id=row.id;
          EMPLOYER.disverify(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("成功取消审核");
              let idx=this.page.list.findIndex((item)=>{
                return item.id==row.id;
              })
              this.page.list[idx].status=0;
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>